<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant
            type="search"
            name="plantCd"
            v-model="searchParam.plantCd"
          ></c-plant>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="휴게시설 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :usePaging="false"
      :filtering="false"
      :columnSetting="false"
      :selection="popupParam.type"
      rowKey="sopRestFacilitiesId"
      @rowDblclick="rowDblclick"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'restFacilitiesPop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'multiple',
        mapFlag: false,
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        mapFlag: false,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'locationName',
            field: 'locationName',
            label: '위치',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'restFacilitiesName',
            field: 'restFacilitiesName',
            label: '구분',
            style: 'width:300px',
            align: "left",
            sortable: true,
            type: 'link'
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '반',
            style: 'width:130px',
            align: "center",
            sortable: true,
          },
          {
            name: 'restFacilitiesCount',
            field: 'restFacilitiesCount',
            label: '휴게시설 개수',
            style: 'width:80px',
            align: "center",
            sortable: true,
            sum: true,
          },
        ],
        data: [],
        height: '450px'
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: false,
        width: '60%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.rtf.restFacilities.list.url
      // code setting
      if (this.popupParam.mapFlag) {
        this.searchParam.mapFlag = this.popupParam.mapFlag;
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = '휴게시설 상세';
      this.popupOptions.param = {
        sopRestFacilitiesId: row ? row.sopRestFacilitiesId : '',
      };
      this.popupOptions.target = () => import(`${'./restFacilitiesDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
